import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import MdTemplate from "../components/MdTemplate";
import Content, { HTMLContent } from "../components/Content";
export const AboutUsTemplate = props => {
  return (
    <Layout>
      <div className="container">
        <section className="section">
          <div className="container">
            <div className="content">
              <h1 className="has-text-weight-bold is-size-2">About Us</h1>
              {/* <MdTemplate
              mdString={direction}
              /> */}
              <HTMLContent content={props.content} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default class Contact extends React.Component {
  render() {
    // const { data } = this.props;
    console.log(this.props.data.allMarkdownRemark.edges[0].node.frontmatter);
    const content = this.props.data.allMarkdownRemark.edges[0].node.html;
    return <AboutUsTemplate content={content} />;
  }
}

export const aboutusQuery = graphql`
  query AboutUsQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            title
            other
          }
        }
      }
    }
  }
`;

// CustomCocktails.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

// export const recipeQuery = graphql`
//   query CustomCocktailsQuery {
//     allCustomCocktailsJson {
//       edges {
//         node {
//           name
//           spirit
//           bitter
//           direction
//           title
//         }
//       }
//     }
//   }
// `;
